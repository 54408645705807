// @ts-nocheck
/* eslint-disable */

'use client';

import React, {useState} from 'react';
import {usePathname} from 'next/navigation';
import FlowPage from '@/flow/FlowPage';
import {generate} from '@modules/Core/components/base/helper';
import {logger} from '@modules/Core/util/Logger';

const ProfileFlowPage: React.FC = () => {
  const path = usePathname();
  const flowName = path.split('/').pop();

  const [instanceKeyId] = useState<string>(`${generate()}`);

  logger.info('[ProfileFlowPage] Initializing flow', {flowName, instanceKeyId});

  return <FlowPage flowName={flowName} />;
};
export default ProfileFlowPage;
